<template>
  <div class="password">
    <div class="form">
      <div class="group">
        <div class="title">
          请输入密码
        </div>
        <van-field
          v-model="form.password"
          center
          :type="passwordTypeStatus ? 'password' : 'text' "
          :formatter="formatter"
          maxlength="12"
          placeholder="6-12位字母与数字组合"
        >
          <template #button>
            <div class="icon" @click="() => { passwordTypeStatus = !passwordTypeStatus }"><img :src="passwordTypeStatus ? hideImg : showImg "></div>
          </template>
        </van-field>
      </div>
      <div class="group">
        <div class="title">请再次输入密码</div>
        <van-field
          v-model="form.confirmPassword"
          center
          :type="confirmPasswordTypeStatus ? 'password' : 'text'"
          :formatter="formatter"
          maxlength="12"
          placeholder="请输入确认新密码"
        >
          <template #button>
            <div class="icon" @click="() => { confirmPasswordTypeStatus = !confirmPasswordTypeStatus }"><img :src="confirmPasswordTypeStatus ? hideImg : showImg "></div>
          </template>
        </van-field>
      </div>
    </div>
    <div class="bottom">
      <van-button
        round
        block
        type="info"
        :disabled="canLogin"
        color="linear-gradient(136deg, #FFB367 0%, #FF7700 100%)"
        @click="goSumbit"
      >
        完成
      </van-button>
    </div>
  </div>
</template>

<script>
import showImg from '@/assets/show@2x.png'
import hideImg from '@/assets/hide@2x.png'
import { rsaEncrypt } from '@/utils/rsa'
import { setPassword } from '@/api/user'
export default {
  data() {
    return {
      form: {
        password: '',
        confirmPassword: ''
      },
      passwordTypeStatus: true,
      confirmPasswordTypeStatus: true,
      showImg,
      hideImg
    }
  },
  computed: {
    canLogin() {
      return this.form.password === '' || this.form.confirmPassword === ''
    },
    userInfos() {
      return this.$store.getters.userInfos
    }
  },
  methods: {
    formatter(val) {
      return val.replace(/[^\w\/]/ig, '')
    },
    async goSumbit() {
      const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/ // 判断是否为6-12位的数字字母组合
      if (!reg.test(this.form.password)) return this.$toast('密码必须为6-12位字母与数字组合！')
      if (!reg.test(this.form.confirmPassword)) return this.$toast('密码必须为6-12位字母与数字组合！')
      if (this.form.password !== this.form.confirmPassword) return this.$toast('两次输入的密码不一致！')
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const password = rsaEncrypt(this.form.password)
      const res = await setPassword({ password })
      loading.clear()
      this.$toast({
        message: res.resp_msg || '操作成功!',
        duration: 1000,
        onClose: async() => {
          this.$router.go(-1)
          await this.$store.dispatch('user/setUserInfos', { isSetPassword: 1 })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.password {
  .form {
    .group {
      border-bottom: 1px solid #F2F2F2;
      .title {
        padding: 16px 16px 10px 16px;
        font-size: 12px;
        color: #666666;
      }
      &:last-child {
        border: 0;
      }
      .icon {
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
        }
      }
      .van-cell {
        padding: 10px 16px;
      }
    }
  }
  .bottom {
    padding: 40px;
    .btn {
      width: 100%;
      height: 44px;
      line-height: 44px;
      font-size: 16px;
      color: #fff;
      text-align: center;
      border-radius: 100px;
      background: #C6C6C6;
    }
  }
}
</style>
