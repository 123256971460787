<template>
  <div class="password">
    <OldPassword v-if="userInfos.isSetPassword" />
    <NewPassword v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NewPassword from './components/NewPassword'
import OldPassword from './components/OldPassword'
export default {
  components: { NewPassword, OldPassword },
  computed: {
    ...mapGetters([
      'userInfos'
    ])
  }
}
</script>
<style lang="scss" scoped>
.password {
  min-height: 100%;
  background: #f8f8fa;
}
</style>

