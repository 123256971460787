<template>
  <div class="password">
    <div class="form">
      <div class="group">
        <div class="title">旧密码</div>
        <van-field
          v-model="form.originalPassword"
          center
          placeholder="请输入旧密码"
          :type="originalPasswordTypeStatus ? 'password' : 'text' "
          :formatter="formatter"
          maxlength="12"
        >
          <template #button>
            <div class="icon" @click="() => { originalPasswordTypeStatus = !originalPasswordTypeStatus }"><img :src="originalPasswordTypeStatus ? hideImg : showImg "></div>
          </template>
        </van-field>
      </div>
      <div class="group">
        <div class="title">新密码</div>
        <van-field
          v-model="form.newPassword"
          center
          placeholder="6-12位字母与数字组合"
          :type="newPasswordTypeStatus ? 'password' : 'text' "
          :formatter="formatter"
          maxlength="12"
        >
          <template #button>
            <div class="icon" @click="() => { newPasswordTypeStatus = !newPasswordTypeStatus }"><img :src="newPasswordTypeStatus ? hideImg : showImg "></div>
          </template>
        </van-field>
      </div>
      <div class="group">
        <div class="title">确认密码</div>
        <van-field
          v-model="form.confirmNewPassword"
          center
          placeholder="请输入确认新密码"
          :type="confirmNewPasswordTypeStatus ? 'password' : 'text' "
          :formatter="formatter"
          maxlength="12"
        >
          <template #button>
            <div class="icon" @click="() => { confirmNewPasswordTypeStatus = !confirmNewPasswordTypeStatus }"><img :src="confirmNewPasswordTypeStatus ? hideImg : showImg "></div>
          </template>
        </van-field>
      </div>
    </div>
    <div class="bottom">
      <van-button
        round
        block
        type="info"
        :disabled="canLogin"
        color="linear-gradient(136deg, #FFB367 0%, #FF7700 100%)"
        @click="goSumbit"
      >
        完成
      </van-button>
    </div>
  </div>
</template>

<script>
import showImg from '@/assets/show@2x.png'
import hideImg from '@/assets/hide@2x.png'
import { rsaEncrypt } from '@/utils/rsa'
import { resetPassword } from '@/api/user'
export default {
  data() {
    return {
      form: {
        originalPassword: '',
        newPassword: '',
        confirmNewPassword: ''
      },
      originalPasswordTypeStatus: true,
      newPasswordTypeStatus: true,
      confirmNewPasswordTypeStatus: true,
      showImg,
      hideImg
    }
  },
  computed: {
    canLogin() {
      return this.form.originalPassword === '' || this.form.newPassword === '' || this.form.confirmNewPassword === ''
    }
  },
  methods: {
    formatter(val) {
      return val.replace(/[^\w\/]/ig, '')
    },
    async goSumbit() {
      const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/ // 判断是否为8-16位的数字字母组合
      if (this.form.originalPassword === '') return this.$toast('请输入旧密码！')
      if (!reg.test(this.form.newPassword)) return this.$toast('新密码必须为6-12位字母与数字组合！')
      if (!reg.test(this.form.confirmNewPassword)) return this.$toast('确认密码必须为6-12位字母与数字组合！')
      if (this.form.newPassword !== this.form.confirmNewPassword) return this.$toast('两次输入的密码不一致！')
      const loading = this.$toast.loading({ forbidClick: true, duration: 0 })
      const oldPassword = rsaEncrypt(this.form.originalPassword)
      const newPassword = rsaEncrypt(this.form.newPassword)
      const res = await resetPassword({ oldPassword, newPassword })
      loading.clear()
      this.$toast({
        message: res.resp_msg || '操作成功!',
        duration: 1000,
        onClose: () => {
          this.$router.go(-1)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.password {
  .form {
    .group {
      border-bottom: 1px solid #F2F2F2;
      &:last-child {
        border: 0;
      }
      .title {
        padding: 16px 16px 10px 16px;
        font-size: 12px;
        color: #666666;
      }
      .icon {
        img {
          width: 20px;
          height: 20px;
        }
      }
      .van-cell {
        padding: 10px 16px;
      }
    }
  }
  .bottom {
    padding: 40px;
    .btn {
      width: 100%;
      height: 44px;
      line-height: 44px;
      font-size: 16px;
      color: #fff;
      text-align: center;
      border-radius: 100px;
      background: #C6C6C6;
    }
  }
}
</style>
